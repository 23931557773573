// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let turtleCost = await store
        .getState()
        .blockchain.smartContract.methods.turtleCost()
        .call();
      let account = await store.getState()["blockchain"]["account"];
      let turtleOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(account)
        .call();
      
      let ewtstakingbalance = await store
        .getState()
        .blockchain.ewtStakingContract.methods.stakes("0x4B65D1614Df4292A0089B5062b2E64c3Ae745916")
        .call();
      let turtleewtstakingbalance = await store
        .getState()
        .blockchain.ewtStakingContract.methods.stakes("0x0690C9892e2DfbD6D7AE5c9601483100F0BBCa57")
        .call();
      //let approveAllowance = await store
      //  .getState()
      //  .blockchain.stakingSmartContract.methods.allowance(account, "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7")
      //  .call();
      //let approveContractAllowance = await store
      //  .getState()
      //  .blockchain.stakingSmartContract.methods.allowance(account, "0x48fB5372C313F74cc55d4FB72d698a237f17E292")
      //  .call();
      //let totalstakedamount = await store
      //  .getState()
      //  .blockchain.smartContract.methods.balanceOf("0x48fB5372C313F74cc55d4FB72d698a237f17E292")
      //  .call();
      //let turtlesStaked = await store
      //  .getState()
      //  .blockchain.stakingSmartContract.methods.getTokensStaked(account)
      //  .call();
      //let earnedShells = await store
      //  .getState()
      //  .blockchain.stakingSmartContract.methods.getAllRewards(account)
      //  .call();
      //let turtleInformation = []
      //for (let i = 0; i < Number(totalSupply)+1; i++) {
      //  let turtleInfo = await store
      //    .getState()
      //    .blockchain.smartContract.methods.turtles(i)
      //    .call();
      //    for (let i2 = 0; i2 < turtleOwner.length; i2++) {
      //      if (turtleInfo[0] === turtleOwner[i2]) {
      //        turtleInformation.push(turtleInfo)
      //      }
      //    }
      //}

      dispatch(
        fetchDataSuccess({
          totalSupply,
          turtleCost,
          turtleOwner,
          ewtstakingbalance,
          turtleewtstakingbalance,
          //approveAllowance,
          //approveContractAllowance,
          //totalstakedamount,
          //turtlesStaked,
          //earnedShells,
          //turtleInformation,
        })
      );
    } catch (err) {
      //console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
