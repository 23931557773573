import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import Header from '../components/Header';
import { db, dbTwo } from '../firebase';
import { onValue, ref, update, get } from 'firebase/database';

const Home = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const [userdata, setUserdata] = useState([]);
    const [amountTurtles, setAmountTurtles] = useState(0);
    useEffect(() => {
        onValue(ref(dbTwo), snapshot => {
            const data = snapshot.val();
            setUserdata(data[String("0x0690c9892e2dfbd6d7ae5c9601483100f0bbca57")])
        });
        if (userdata !== [] && userdata !== undefined && userdata.length !== 0) {
            setAmountTurtles(userdata["nfts"][27]["amount"])
        }
    }, [data]);

    const [turtleinfo, setTurtleinfo] = useState([]);
    useEffect(() => {
        onValue(ref(db), snapshot => {
            const data = snapshot.val();
            setTurtleinfo(data[27]["cheapestpriceoriginal"])
        });
    }, [data]);

    const [ewtprice, setEwtprice] = useState(0);
    async function getewtprice() {
        let request = await fetch('https://explorer.energyweb.org/api?module=stats&action=coinprice')
        let ewtprice = await request.json()
        ewtprice = ewtprice["result"]["coin_usd"]
        setEwtprice(ewtprice)
    }
    getewtprice()

    console.log(ewtprice)   
    console.log(amountTurtles)    

    const hexToDecimal = hex => parseInt(hex, 16);
    const [tubbyTurtlesContractBalance, setTubbyTurtlesContractBalance] = useState(0)
    const [paymentSplitterBalance, setPaymentSplitterBalance] = useState(0)
    const [tubbyTurtlesMainBalance, setTubbyTurtlesMainBalance] = useState(0)
    const [tubbyTurtlesDonationBalance, setTubbyTurtlesDonationBalance] = useState(0)
    const [tubbyTurtlesLiquidityBalance, setTubbyTurtlesLiquidityBalance] = useState(0)
    const [energywebnftsBalance, setEnergywebnftsBalance] = useState(0)
    const [energywebplaceBalance, setEnergywebplaceBalance] = useState(0)
    const getTubbyTurtlesContractBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7"
        ).then((response) => response.json());
        setTubbyTurtlesContractBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getPaymentSplitterBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0xa340dD58B4720735eFec6aBEcb8096cfe52DD9dB"
        ).then((response) => response.json());
        setPaymentSplitterBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesMainBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x0690C9892e2DfbD6D7AE5c9601483100F0BBCa57"
        ).then((response) => response.json());
        setTubbyTurtlesMainBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesDonationBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0xCaFAdF88B3E6B9A9aDF4b04c23F442aA95452928"
        ).then((response) => response.json());
        setTubbyTurtlesDonationBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesLiquidityBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x6Ef3D22D4fC7A48a76477e088430e6a720F7a22e"
        ).then((response) => response.json());
        setTubbyTurtlesLiquidityBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getEnergywebnftsBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0xc52752f731bb0F20C7C1f0110c6FC96C540C43B6"
        ).then((response) => response.json());
        setEnergywebnftsBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getEnergywebplaceBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x71dB1A52b9E684AC73b3041a67AE9d39A6515591"
        ).then((response) => response.json());
        setEnergywebplaceBalance(hexToDecimal(response["result"])/1000000000000000000);
    };

    useEffect(() => {
        getTubbyTurtlesContractBalance();
        getPaymentSplitterBalance();
        getTubbyTurtlesMainBalance();
        getTubbyTurtlesDonationBalance();
        getTubbyTurtlesLiquidityBalance();
        getEnergywebnftsBalance();
        getEnergywebplaceBalance();
    }, []);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    async function changeTurtleName() {
        let newNameChageCost = document.getElementById('newNameChangeCost').value
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.setNameChangeCost(newNameChageCost)
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function changeTurtleCost() {
        let newTurtleCost = document.getElementById('newTurtleCost').value
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.setTurtleCost(newTurtleCost)
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function pauseTubbyTurtles() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.pause('true')
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function resumeTubbyTurtles() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.pause('false')
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function withdrawBalance() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.withdraw()
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    // Payment Splitter
    async function releaseEwtToAddressPayee1() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.paymentSplitterSmartContract.methods.release("0x0690C9892e2DfbD6D7AE5c9601483100F0BBCa57")
            .send({
                to: "0xa340dD58B4720735eFec6aBEcb8096cfe52DD9dB",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }
    async function releaseEwtToAddressPayee2() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.paymentSplitterSmartContract.methods.release("0xCaFAdF88B3E6B9A9aDF4b04c23F442aA95452928")
            .send({
                to: "0xa340dD58B4720735eFec6aBEcb8096cfe52DD9dB",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }
    async function releaseEwtToAddressPayee3() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.paymentSplitterSmartContract.methods.release("0x6Ef3D22D4fC7A48a76477e088430e6a720F7a22e")
            .send({
                to: "0xa340dD58B4720735eFec6aBEcb8096cfe52DD9dB",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    console.log(data)
    console.log(blockchain)

    function toPlainString(num) {return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {return e < 0 ? b + '0.' + Array(1 - e - c.length).join(0) + c + d : b + c + d + Array(e - d.length + 1).join(0);});};
    function convertToWei() {
        if (data["ewtstakingbalance"] !== 0) {
            let etherToWeiInput = document.getElementById('etherToWeiConverter');
            let toConvert = etherToWeiInput.value;

            toConvert = toPlainString(toConvert * 1000000000000000000);

            let convertedToWeiInput = document.getElementById('convertedToWei');
            convertedToWeiInput.value = toConvert;
        }
    }
    setInterval(function () {
        convertToWei();
    }, 500);

    // Ew Place
    async function withdrawEwPlaceBalance() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.ewPlaceContract.methods.withdraw()
            .send({
                to: "0x71dB1A52b9E684AC73b3041a67AE9d39A6515591",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    return (
        <div className='w-[100vw] min-h-[100vh] bg-[#2e3133]'>
            <Header />
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <div className='p-4 text-darktextprimary'>
                    <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                        <div>
                            <div>
                                <p>
                                    You are not logged in yet
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                ) : (
                    blockchain.account === "0x0690c9892e2dfbd6d7ae5c9601483100f0bbca57" || blockchain.account === "0xc52752f731bb0f20c7c1f0110c6fc96c540c43b6" ? (
                    <div className='p-4 text-darktextprimary'>
                        <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                            <div>
                                <div>
                                    <p className='text-green-200'>
                                        You are now logged in with the admin account: {blockchain.account}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                            <h1 className='text-lg font-bold'>EWT to wei converter</h1>
                            <input id='etherToWeiConverter' className='bg-[#5e5e61] rounded-lg py-[2px] px-[5px] w-[100px]' placeholder="Ether to wei" type="text" />
                            <input id='convertedToWei' className='bg-[#5e5e61] rounded-lg py-[2px] px-[5px] w-[250px] ml-2' readOnly defaultValue="Converted amount in wei" placeholder="Ether to wei" type="text" />
                        </div>
                        <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                            <h1 className='text-lg font-bold'>Tubby Turtles Contract</h1>
                            <div className='flex flex-col'>
                                <div className='my-[4px]'>
                                    <button onClick={changeTurtleName} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>setNameChangeCost</button>
                                    <input id='newNameChangeCost' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[220px]' placeholder="New nameChangeCost in wei" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={changeTurtleCost} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>setTurtleCost</button>
                                    <input id='newTurtleCost' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[220px]' placeholder="New turtleCost in wei" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={pauseTubbyTurtles} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>pause</button>
                                    <button onClick={resumeTubbyTurtles} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] ml-2 rounded-lg w-fit'>resume</button>
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex flex-col'>
                                    <div className='my-[4px] flex flex-row border-b-2 border-white'>
                                        <p className='w-[62px] mr-2'>Function</p>
                                        <p className='w-[223px] mr-2'>Address names</p>
                                        <p className='w-[385px]'>Address</p>
                                        <p className='w-[70px] ml-2'>Balance</p>
                                    </div>
                                    <div className='my-[4px] flex flex-row'>
                                        <button onClick={withdrawBalance} className='w-[62px] hover:brightness-125 mr-2 bg-red-700 px-[5px] py-[2px] text-[0.81rem] rounded-lg'>withdraw</button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>Tubby Turtles Contract</p>
                                        <p className='w-[385px] overflow-hidden'>0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7</p>
                                        <p className='w-[70px] ml-2'>{tubbyTurtlesContractBalance.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#36393b] mt-4 p-4 rounded-md'>
                            <h1 className='text-lg font-bold'>Payment splitter contract</h1>
                            <div className='flex flex-col'>
                                <div className='flex flex-col'>
                                    <div className='my-[4px] flex flex-row border-b-2 border-white'>
                                        <p className='w-[62px] mr-2'>Function</p>
                                        <p className='w-[223px] mr-2'>Address names</p>
                                        <p className='w-[385px]'>Address</p>
                                        <p className='w-[70px] ml-2'>Balance</p>
                                    </div>
                                    <div className='my-[4px] h-[28px] flex flex-row'>
                                        <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>PaymentSplitter Contract</p>
                                        <p className='w-[385px] overflow-hidden'>0xa340dD58B4720735eFec6aBEcb8096cfe52DD9dB</p>
                                        <p className='w-[70px] ml-2'>{paymentSplitterBalance.toFixed(2)}</p>
                                    </div>
                                    <div className='my-[4px] flex flex-row'>
                                        <button onClick={releaseEwtToAddressPayee1} className='w-[62px] hover:brightness-125 mr-2 bg-red-700 px-[5px] py-[2px] rounded-lg'>release</button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>Tubby Turtles Main Wallet</p>
                                        <p className='w-[385px] overflow-hidden'>0x0690C9892e2DfbD6D7AE5c9601483100F0BBCa57</p>
                                        <p className='w-[70px] ml-2'>{tubbyTurtlesMainBalance.toFixed(2)}</p>
                                    </div>
                                    <div className='my-[4px] flex flex-row'>
                                        <button onClick={releaseEwtToAddressPayee2} className='w-[62px] hover:brightness-125 mr-2 bg-red-700 px-[5px] py-[2px] rounded-lg'>release</button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>Tubby Turtles Donation Wallet</p>
                                        <p className='w-[385px] overflow-hidden'>0xCaFAdF88B3E6B9A9aDF4b04c23F442aA95452928</p>
                                        <p className='w-[70px] ml-2'>{tubbyTurtlesDonationBalance.toFixed(2)}</p>
                                    </div>
                                    <div className='my-[4px] flex flex-row'>
                                        <button onClick={releaseEwtToAddressPayee3} className='w-[62px] hover:brightness-125 mr-2 bg-red-700 px-[5px] py-[2px] rounded-lg'>release</button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>Tubby Turtles Liquidity Address</p>
                                        <p className='w-[385px] overflow-hidden'>0x6Ef3D22D4fC7A48a76477e088430e6a720F7a22e</p>
                                        <p className='w-[70px] ml-2'>{tubbyTurtlesLiquidityBalance.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#36393b] mt-4 p-4 rounded-md'>
                            <h1 className='text-lg font-bold'>EnergyWebNFTs.com</h1>
                            <div className='flex flex-col'>
                                <div className='flex flex-col'>
                                    <div className='my-[4px] flex flex-row border-b-2 border-white'>
                                        <p className='w-[62px] mr-2'>Function</p>
                                        <p className='w-[223px] mr-2'>Address names</p>
                                        <p className='w-[385px]'>Address</p>
                                        <p className='w-[70px] ml-2'>Balance</p>
                                    </div>
                                    <div className='my-[4px] h-[28px] flex flex-row'>
                                        <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>EnergyWebNFTs Deployer</p>
                                        <p className='w-[385px] overflow-hidden'>0xc52752f731bb0F20C7C1f0110c6FC96C540C43B6</p>
                                        <p className='w-[70px] ml-2'>{energywebnftsBalance.toFixed(2)}</p>
                                    </div>
                                    <div className='my-[4px] flex flex-row'>
                                        <button onClick={withdrawEwPlaceBalance} className='w-[62px] hover:brightness-125 mr-2 bg-red-700 px-[5px] py-[2px] text-[0.81rem] rounded-lg'>withdraw</button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>EnergyWebPlace Contract</p>
                                        <p className='w-[385px] overflow-hidden'>0x71dB1A52b9E684AC73b3041a67AE9d39A6515591</p>
                                        <p className='w-[70px] ml-2'>{energywebplaceBalance.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#36393b] mt-4 p-4 rounded-md'>
                            <div className='flex flex-col'>
                                <div className='flex flex-col'>
                                    <div className='h-[28px] flex flex-row'>
                                        <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                        <p className='w-[385px] overflow-hidden'></p>
                                        <p className='w-[70px] ml-2'>{(tubbyTurtlesContractBalance+paymentSplitterBalance+tubbyTurtlesMainBalance+tubbyTurtlesDonationBalance+tubbyTurtlesLiquidityBalance+energywebnftsBalance+energywebplaceBalance).toFixed(2)}</p>
                                        <p>Total</p>
                                    </div>
                                    <div className='h-[28px] flex flex-row'>
                                        <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                        <p className='w-[385px] overflow-hidden'></p>
                                        <p className='w-[70px] ml-2'>{(paymentSplitterBalance+tubbyTurtlesDonationBalance+tubbyTurtlesLiquidityBalance).toFixed(2)}</p>
                                        <p>(Not mine)</p>
                                    </div>
                                    <div className='h-[28px] flex flex-row'>
                                        <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                        <p className='w-[385px] overflow-hidden'></p>
                                        <p className='w-[70px] ml-2 border-b-2 border-white'>{(tubbyTurtlesContractBalance+tubbyTurtlesMainBalance+energywebnftsBalance+energywebplaceBalance).toFixed(2)}</p>
                                        <p className='border-b-2 border-white'>My Total (Total - Not mine)</p>
                                    </div>
                                    {data["ewtstakingbalance"] !== 0 ? (
                                        <div>
                                            <div className='my-[4px] h-[28px] flex flex-row'>
                                                <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                                <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                                <p className='w-[385px] overflow-hidden'></p>
                                                <p className='w-[70px] ml-2 border-b-2 border-white'>{(amountTurtles*turtleinfo).toFixed(2)}</p>
                                                <p className='border-b-2 border-white'>Amount of owned turtles ({amountTurtles})</p>
                                            </div>
                                            <div className='my-[4px] h-[28px] flex flex-row'>
                                                <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                                <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                                <p className='w-[385px] overflow-hidden'></p>
                                                <p className='w-[70px] ml-2 border-b-2 border-white'>{25}</p>
                                                <p className='border-b-2 border-white'>Soot and tree</p>
                                            </div>
                                            <div className='my-[4px] h-[28px] flex flex-row'>
                                                <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                                <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                                <p className='w-[385px] overflow-hidden'></p>
                                                <p className='w-[70px] ml-2'>{(data["ewtstakingbalance"][1] / 1000000000000000000).toFixed(2)}</p>
                                                <p>Staking Balance</p>
                                            </div>
                                            <div className='my-[4px] h-[28px] flex flex-row'>
                                                <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                                <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                                <p className='w-[385px] overflow-hidden'></p>
                                                <p className='w-[70px] ml-2 border-b-2 border-white'>{(data["turtleewtstakingbalance"][1] / 1000000000000000000).toFixed(2)}</p>
                                                <p className='border-b-2 border-white'>Turtle Dev Staking Balance</p>
                                            </div>
                                            <div className='my-[4px] h-[28px] flex flex-row'>
                                                <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                                <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                                <p className='w-[385px] overflow-hidden'></p>
                                                <p className='w-[70px] ml-2'>{((tubbyTurtlesContractBalance+tubbyTurtlesMainBalance+energywebnftsBalance+energywebplaceBalance)+(amountTurtles*turtleinfo)+(25)+(data["ewtstakingbalance"][1] / 1000000000000000000)+(data["turtleewtstakingbalance"][1] / 1000000000000000000)).toFixed(2)}</p>
                                                <p>Final Total</p>
                                            </div>
                                            <div className='my-[4px] h-[28px] flex flex-row'>
                                                <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                                <p className='w-[223px] mr-2 whitespace-nowrap'></p>
                                                <p className='w-[385px] overflow-hidden'></p>
                                                <p className='w-[70px] ml-2'>$ {(((tubbyTurtlesContractBalance+tubbyTurtlesMainBalance+energywebnftsBalance+energywebplaceBalance)+(amountTurtles*turtleinfo)+(25)+(data["ewtstakingbalance"][1] / 1000000000000000000)+(data["turtleewtstakingbalance"][1] / 1000000000000000000))*parseFloat(ewtprice)).toFixed(0)}</p>
                                                <p>Final USD Total</p>
                                            </div>
                                        </div>
                                        ) : (<></>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div className='p-4 text-darktextprimary'>
                            <div className='bg-[#36393b] mb-4 p-4'>
                                <div>
                                    <div>
                                        <p>
                                            You are logged in, but you are not an admin.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
    );
}

export default Home;