import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/Home';
import TubbyTurtles from './pages/TubbyTurtles';
import EwPlace from './pages/EwPlace';

import store from './redux/store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Router>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tubbyturtles" element={<TubbyTurtles />} />
          <Route path="/ewplace" element={<EwPlace />} />
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;