import tttlogo from '../assets/images/tttlogo.png';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import Header from '../components/Header';

const TubbyTurtles = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const hexToDecimal = hex => parseInt(hex, 16);
    const [tubbyTurtlesContractBalance, setTubbyTurtlesContractBalance] = useState(0)
    const [paymentSplitterBalance, setPaymentSplitterBalance] = useState(0)
    const [tubbyTurtlesMainBalance, setTubbyTurtlesMainBalance] = useState(0)
    const [tubbyTurtlesDonationBalance, setTubbyTurtlesDonationBalance] = useState(0)
    const [tubbyTurtlesLiquidityBalance, setTubbyTurtlesLiquidityBalance] = useState(0)
    const getTubbyTurtlesContractBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7"
        ).then((response) => response.json());
        setTubbyTurtlesContractBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getPaymentSplitterBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0xa340dD58B4720735eFec6aBEcb8096cfe52DD9dB"
        ).then((response) => response.json());
        setPaymentSplitterBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesMainBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x0690C9892e2DfbD6D7AE5c9601483100F0BBCa57"
        ).then((response) => response.json());
        setTubbyTurtlesMainBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesDonationBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0xCaFAdF88B3E6B9A9aDF4b04c23F442aA95452928"
        ).then((response) => response.json());
        setTubbyTurtlesDonationBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesLiquidityBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x6Ef3D22D4fC7A48a76477e088430e6a720F7a22e"
        ).then((response) => response.json());
        setTubbyTurtlesLiquidityBalance(hexToDecimal(response["result"])/1000000000000000000);
    };


    useEffect(() => {
        getTubbyTurtlesContractBalance();
        getPaymentSplitterBalance();
        getTubbyTurtlesMainBalance();
        getTubbyTurtlesDonationBalance();
        getTubbyTurtlesLiquidityBalance();
    }, []);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    async function changeTurtleName() {
        let newNameChageCost = document.getElementById('newNameChangeCost').value
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.setNameChangeCost(newNameChageCost)
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function changeTurtleCost() {
        let newTurtleCost = document.getElementById('newTurtleCost').value
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.setTurtleCost(newTurtleCost)
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function pauseTubbyTurtles() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.pause('true')
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function resumeTubbyTurtles() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.pause('false')
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function changeMaxNameLength() {
        let newMaxNameLength = document.getElementById('newMaxNameLength').value
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.setMaxNameLength(newMaxNameLength)
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function setShellTokenAddress() {
        let newShellTokenAddress = document.getElementById('newShellTokenAddress').value
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.setMaxNameLength(newShellTokenAddress)
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function setRoyalties() {
        let newRoyaltyReceipt = document.getElementById('newRoyaltyReceipt').value
        let newRoyaltyPercentage = document.getElementById('newRoyaltyPercentage').value
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.setRoyalties(newRoyaltyReceipt, newRoyaltyPercentage)
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function withdrawBalance() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.withdraw()
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    async function getMaxNameLength() {
        let maxNameLength = await blockchain.smartContract.methods.maxNameLength().call();
        document.getElementById('getMaxNameLength').value = maxNameLength;
    }

    async function getNameChangeCost() {
        let nameChangeCost = await blockchain.smartContract.methods.nameChangeCost().call();
        document.getElementById('getNameChangeCost').value = nameChangeCost;
    }

    async function getPaused() {
        let getpaused = await blockchain.smartContract.methods.paused().call();
        document.getElementById('getpaused').value = getpaused;
    }

    async function getShellToken() {
        let getShellToken = await blockchain.smartContract.methods.shellToken().call();
        document.getElementById('getShellToken').value = getShellToken;
    }

    async function getTotalSupply() {
        let getTotalSupply = await blockchain.smartContract.methods.totalSupply().call();
        document.getElementById('getTotalSupply').value = getTotalSupply;
    }

    async function getTurtleCost() {
        let getTurtleCost = await blockchain.smartContract.methods.turtleCost().call();
        document.getElementById('getTurtleCost').value = getTurtleCost;
    }

    function toPlainString(num) {return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {return e < 0 ? b + '0.' + Array(1 - e - c.length).join(0) + c + d : b + c + d + Array(e - d.length + 1).join(0);});};
    function convertToWei() {
        let etherToWeiInput = document.getElementById('etherToWeiConverter');
        let toConvert = etherToWeiInput.value;

        toConvert = toPlainString(toConvert * 1000000000000000000);

        let convertedToWeiInput = document.getElementById('convertedToWei');
        convertedToWeiInput.value = toConvert;
    }
    function convertToEther() {
        let weiToEtherInput = document.getElementById('weiToEther');
        let toConvert = weiToEtherInput.value;

        toConvert = toPlainString(toConvert / 1000000000000000000);

        let convertedToEtherInput = document.getElementById('convertedToEther');
        convertedToEtherInput.value = toConvert;
    }
    setInterval(function () {
        convertToEther()
        convertToWei();
    }, 500);

    return (
        <div className='w-[100vw] min-h-[100vh] bg-[#2e3133]'>
            <Header />
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <div className='p-4 text-darktextprimary'>
                    <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                        <div>
                            <div>
                                <p>
                                    You are not logged in yet
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                ) : (
                    blockchain.account === "0x0690c9892e2dfbd6d7ae5c9601483100f0bbca57" ? (
                    <div className='p-4 text-darktextprimary'>
                        <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                            <div>
                                <div>
                                    <p className='text-green-200'>
                                        You are now logged in with the <img src={tttlogo} className='rounded-md inline-block h-[24px]' /> admin account: {blockchain.account}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                            <h1 className='text-lg font-bold'>EWT to wei converter</h1>
                            <input id='etherToWeiConverter' className='bg-[#5e5e61] rounded-lg py-[2px] px-[5px] w-[100px]' placeholder="Ether to wei" type="text" />
                            <input id='convertedToWei' className='bg-[#5e5e61] rounded-lg py-[2px] px-[5px] w-[250px] ml-2' readOnly defaultValue="Converted amount in wei" placeholder="Ether to wei" type="text" />
                            <h1 className='text-lg font-bold'>wei to EWT converter</h1>
                            <input id='weiToEther' className='bg-[#5e5e61] rounded-lg py-[2px] px-[5px] w-[100px]' placeholder="wei to Ether" type="text" />
                            <input id='convertedToEther' className='bg-[#5e5e61] rounded-lg py-[2px] px-[5px] w-[250px] ml-2' readOnly defaultValue="Converted amount in EWT" placeholder="wei to Ether" type="text" />
                        </div>
                        <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                            <h1 className='text-lg font-bold'>Tubby Turtles Contract</h1>
                            <div className='flex flex-col'>
                                <div className='my-[4px]'>
                                    <button onClick={changeTurtleName} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>setNameChangeCost</button>
                                    <input id='newNameChangeCost' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="uint256 nameChangeCost in wei" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={changeTurtleCost} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>setTurtleCost</button>
                                    <input id='newTurtleCost' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="uint256 turtleCost in wei" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={pauseTubbyTurtles} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>pause</button>
                                    <button onClick={resumeTubbyTurtles} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] ml-2 rounded-lg w-fit'>resume</button>
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={changeMaxNameLength} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>setMaxNameLength</button>
                                    <input id='newMaxNameLength' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="uint8 newMaxNameLength" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={setShellTokenAddress} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>setShellTokenAddress</button>
                                    <input id='newShellTokenAddress' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="address newShellTokenAddress" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={setRoyalties} className='hover:brightness-125 bg-orange-500 px-[5px] py-[2px] rounded-lg w-fit'>setRoyalties</button>
                                    <input id='newRoyaltyReceipt' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="address newRoyaltyReceipt" type="text" />
                                    <input id='newRoyaltyPercentage' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="uint256 newRoyaltyPercent*100" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={getMaxNameLength} className='hover:brightness-125 bg-blue-500 px-[5px] py-[2px] rounded-lg w-fit'>getMaxNameLength</button>
                                    <input id='getMaxNameLength' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="maxNameLength" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={getNameChangeCost} className='hover:brightness-125 bg-blue-500 px-[5px] py-[2px] rounded-lg w-fit'>getNameChangeCost</button>
                                    <input id='getNameChangeCost' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="nameChangeCost" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={getPaused} className='hover:brightness-125 bg-blue-500 px-[5px] py-[2px] rounded-lg w-fit'>getPaused</button>
                                    <input id='getpaused' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="bool paused" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={getShellToken} className='hover:brightness-125 bg-blue-500 px-[5px] py-[2px] rounded-lg w-fit'>getShellToken</button>
                                    <input id='getShellToken' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="address shellToken" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={getTotalSupply} className='hover:brightness-125 bg-blue-500 px-[5px] py-[2px] rounded-lg w-fit'>getTotalSupply</button>
                                    <input id='getTotalSupply' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="uint256 totalSupply" type="text" />
                                </div>
                                <div className='my-[4px]'>
                                    <button onClick={getTurtleCost} className='hover:brightness-125 bg-blue-500 px-[5px] py-[2px] rounded-lg w-fit'>getTurtleCost</button>
                                    <input id='getTurtleCost' className='bg-[#5e5e61] rounded-lg py-[2px] ml-2 px-[5px] w-[250px]' placeholder="uint256 turtleCost in wei" type="text" />
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex flex-col'>
                                    <div className='my-[4px] flex flex-row border-b-2 border-white'>
                                        <p className='w-[62px] mr-2'>Function</p>
                                        <p className='w-[223px] mr-2'>Address names</p>
                                        <p className='w-[385px]'>Address</p>
                                        <p className='w-[70px] ml-2'>Balance</p>
                                    </div>
                                    <div className='my-[4px] flex flex-row'>
                                        <button onClick={withdrawBalance} className='w-[62px] hover:brightness-125 mr-2 bg-red-700 px-[5px] py-[2px] text-[0.81rem] rounded-lg'>withdraw</button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>Tubby Turtles Contract</p>
                                        <p className='w-[385px] overflow-hidden'>0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7</p>
                                        <p className='w-[70px] ml-2'>{tubbyTurtlesContractBalance.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div className='p-4 text-darktextprimary'>
                            <div className='bg-[#36393b] mb-4 p-4'>
                                <div>
                                    <div>
                                        <p>
                                            You are logged in, but you are not an admin.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
    );
}

export default TubbyTurtles;