const initialState = {
  loading: false,
  totalSupply: 0,
  turtleCost: 0,
  turtleOwner: [],
  ewtstakingbalance: 0,
  turtleewtstakingbalance: 0,
  //approveAllowance: 0,
  //approveContractAllowance: 0,
  //totalstakedamount: 0,
  //turtlesStaked: [],
  //earnedShells: 0,
  //turtleInformation: "",
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        turtleCost: action.payload.turtleCost,
        turtleOwner: action.payload.turtleOwner,
        ewtstakingbalance: action.payload.ewtstakingbalance,
        turtleewtstakingbalance: action.payload.turtleewtstakingbalance,
        //approveAllowance: action.payload.approveAllowance,
        //approveContractAllowance: action.payload.approveContractAllowance,
        //totalstakedamount: action.payload.totalstakedamount,
        //turtlesStaked: action.payload.turtlesStaked,
        //earnedShells: action.payload.earnedShells,
        //turtleInformation: action.payload.turtleInformation,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
