import ewplace from '../assets/images/ewplace.png';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import Header from '../components/Header';

const EwPlace = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const hexToDecimal = hex => parseInt(hex, 16);
    const [tubbyTurtlesContractBalance, setTubbyTurtlesContractBalance] = useState(0)
    const [paymentSplitterBalance, setPaymentSplitterBalance] = useState(0)
    const [tubbyTurtlesMainBalance, setTubbyTurtlesMainBalance] = useState(0)
    const [tubbyTurtlesDonationBalance, setTubbyTurtlesDonationBalance] = useState(0)
    const [tubbyTurtlesLiquidityBalance, setTubbyTurtlesLiquidityBalance] = useState(0)
    const [energywebnftsBalance, setEnergywebnftsBalance] = useState(0)
    const [energywebplaceBalance, setEnergywebplaceBalance] = useState(0)
    const getTubbyTurtlesContractBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7"
        ).then((response) => response.json());
        setTubbyTurtlesContractBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getPaymentSplitterBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x2ac86c1da3F901E61a88D84Fe0A94daEd57Eb6A6"
        ).then((response) => response.json());
        setPaymentSplitterBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesMainBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x0690C9892e2DfbD6D7AE5c9601483100F0BBCa57"
        ).then((response) => response.json());
        setTubbyTurtlesMainBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesDonationBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0xCaFAdF88B3E6B9A9aDF4b04c23F442aA95452928"
        ).then((response) => response.json());
        setTubbyTurtlesDonationBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getTubbyTurtlesLiquidityBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x6Ef3D22D4fC7A48a76477e088430e6a720F7a22e"
        ).then((response) => response.json());
        setTubbyTurtlesLiquidityBalance(hexToDecimal(response["result"])/1000000000000000000);
    };
    const getEnergywebnftsBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0xc52752f731bb0F20C7C1f0110c6FC96C540C43B6"
        ).then((response) => response.json());
        setEnergywebnftsBalance(hexToDecimal(response["result"])/1000000000000000000);
    };

    const getEnergywebplaceBalance = async () => {
        const response = await fetch(
            "https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x71dB1A52b9E684AC73b3041a67AE9d39A6515591"
        ).then((response) => response.json());
        setEnergywebplaceBalance(hexToDecimal(response["result"])/1000000000000000000);
    };


    useEffect(() => {
        getTubbyTurtlesContractBalance();
        getPaymentSplitterBalance();
        getTubbyTurtlesMainBalance();
        getTubbyTurtlesDonationBalance();
        getTubbyTurtlesLiquidityBalance();
        getEnergywebnftsBalance();
        getEnergywebplaceBalance();
    }, []);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    // Payment Splitter
    async function releaseEwtToAddressPayee1() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.paymentSplitterSmartContract.methods.release("0x0690C9892e2DfbD6D7AE5c9601483100F0BBCa57")
            .send({
                to: "0x2ac86c1da3F901E61a88D84Fe0A94daEd57Eb6A6",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }
    async function releaseEwtToAddressPayee2() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.paymentSplitterSmartContract.methods.release("0xCaFAdF88B3E6B9A9aDF4b04c23F442aA95452928")
            .send({
                to: "0x2ac86c1da3F901E61a88D84Fe0A94daEd57Eb6A6",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }
    async function releaseEwtToAddressPayee3() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.paymentSplitterSmartContract.methods.release("0x6Ef3D22D4fC7A48a76477e088430e6a720F7a22e")
            .send({
                to: "0x2ac86c1da3F901E61a88D84Fe0A94daEd57Eb6A6",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    function toPlainString(num) {return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {return e < 0 ? b + '0.' + Array(1 - e - c.length).join(0) + c + d : b + c + d + Array(e - d.length + 1).join(0);});};
    function convertToWei() {
        let etherToWeiInput = document.getElementById('etherToWeiConverter');
        let toConvert = etherToWeiInput.value;

        toConvert = toPlainString(toConvert * 1000000000000000000);

        let convertedToWeiInput = document.getElementById('convertedToWei');
        convertedToWeiInput.value = toConvert;
    }
    setInterval(function () {
        convertToWei();
    }, 500);

    // Ew Place
    async function withdrawEwPlaceBalance() {
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.ewPlaceContract.methods.withdraw()
            .send({
                to: "0x71dB1A52b9E684AC73b3041a67AE9d39A6515591",
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 10000000,
            })
    }

    return (
        <div className='w-[100vw] min-h-[100vh] bg-[#2e3133]'>
            <Header />
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <div className='p-4 text-darktextprimary'>
                    <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                        <div>
                            <div>
                                <p>
                                    You are not logged in yet
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                ) : (
                    blockchain.account === "0xc52752f731bb0f20c7c1f0110c6fc96c540c43b6" ? (
                    <div className='p-4 text-darktextprimary'>
                        <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                            <div>
                                <div>
                                    <p className='text-green-200'>
                                        You are now logged in with the <img src={ewplace} className='rounded-md inline-block h-[24px]' /> admin account: {blockchain.account}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#36393b] mb-4 p-4 rounded-md'>
                            <h1 className='text-lg font-bold'>EWT to wei converter</h1>
                            <input id='etherToWeiConverter' className='bg-[#5e5e61] rounded-lg py-[2px] px-[5px] w-[100px]' placeholder="Ether to wei" type="text" />
                            <input id='convertedToWei' className='bg-[#5e5e61] rounded-lg py-[2px] px-[5px] w-[250px] ml-2' readOnly defaultValue="Converted amount in wei" placeholder="Ether to wei" type="text" />
                        </div>
                        <div className='bg-[#36393b] mt-4 p-4 rounded-md'>
                            <h1 className='text-lg font-bold'>EnergyWebNFTs.com</h1>
                            <div className='flex flex-col'>
                                <div className='flex flex-col'>
                                    <div className='my-[4px] flex flex-row border-b-2 border-white'>
                                        <p className='w-[62px] mr-2'>Function</p>
                                        <p className='w-[223px] mr-2'>Address names</p>
                                        <p className='w-[385px]'>Address</p>
                                        <p className='w-[70px] ml-2'>Balance</p>
                                    </div>
                                    <div className='my-[4px] h-[28px] flex flex-row'>
                                        <button className='w-[62px] hover:brightness-125 mr-2 px-[5px] py-[2px] rounded-lg'></button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>EnergyWebNFTs Deployer</p>
                                        <p className='w-[385px] overflow-hidden'>0xc52752f731bb0F20C7C1f0110c6FC96C540C43B6</p>
                                        <p className='w-[70px] ml-2'>{energywebnftsBalance.toFixed(2)}</p>
                                    </div>
                                    <div className='my-[4px] flex flex-row'>
                                        <button onClick={withdrawEwPlaceBalance} className='w-[62px] hover:brightness-125 mr-2 bg-red-700 px-[5px] py-[2px] text-[0.81rem] rounded-lg'>withdraw</button>
                                        <p className='w-[223px] mr-2 whitespace-nowrap'>EnergyWebPlace Contract</p>
                                        <p className='w-[385px] overflow-hidden'>0x71dB1A52b9E684AC73b3041a67AE9d39A6515591</p>
                                        <p className='w-[70px] ml-2'>{energywebplaceBalance.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div className='p-4 text-darktextprimary'>
                            <div className='bg-[#36393b] mb-4 p-4'>
                                <div>
                                    <div>
                                        <p>
                                            You are logged in, but you are not an admin.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
    );
}

export default EwPlace;